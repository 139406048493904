import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PropertyCard from "gatsby-theme-starberry-lomondgroup/src/components/PropertyCard/PropertyCard";
import Slider from "react-slick";

import "gatsby-theme-starberry-lomondgroup/src/components/Features/Features.scss";

//import {FeaturedProperties} from "../../common/queries/common_use_query"

const Features = ({Module, menu_slug}) => {
    
    //console.log("menu_slug", menu_slug);

    const data = useStaticQuery(graphql`
      query FeaturedPropertyQueryCustom{
        glstrapi {
            sales_buy_properties:properties(sort:"sort_date:desc",where:{publish:true, department:"residential", search_type:"sales", status_in:["For Sale"]}, limit:12){
                id
                display_address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                images
                imagetransforms
                available_from
                extra
            }
            sales_sell_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"sales", status_in:["Sale Agreed"]}, limit:12){
                id
                display_address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                images
                imagetransforms
                available_from
                extra
            }

            collection_properties:properties(sort:"price:asc",where:{publish:true, department:"residential", search_type:"sales", status_in:["For Sale"], price_gte:1000000}, limit:12){
                id
                display_address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                images
                imagetransforms
                available_from
                extra
            }

            to_let_properties:properties(sort:"sort_date:desc",where:{publish:true, department:"residential", search_type:"lettings", status_in:["To Let"]}, limit:12){
                id
                display_address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                images
                imagetransforms
                available_from
                extra
            }

            let_agreed_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"lettings", status_in:["Let Agreed"]}, limit:12){
                id
                display_address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                images
                imagetransforms
                available_from
                extra
            }
        }
    }`)

    //var sales_properties = data.glstrapi?.sales_properties;
    var sales_properties = menu_slug?.includes("sell") ? data.glstrapi?.sales_sell_properties : menu_slug?.includes("collection") ? data.glstrapi?.collection_properties : data.glstrapi?.sales_buy_properties;

    var let_properties = menu_slug?.includes("landlords") ? data.glstrapi?.let_agreed_properties : data.glstrapi?.to_let_properties;

    var srch_type = (menu_slug?.includes("rent") || menu_slug?.includes("landlords")) ? "lettings" : "sales"

    const[department, setDepartment] = useState("residential")
    const[search_type, setSearchType] = useState("sales")
    const[properties_list, setPropertiesList] = useState(sales_properties)

    //const{loading, error, data} = FeaturedProperties(department, search_type);

    useEffect(()=>{
        if(Module?.tab_list?.length > 0){
            var split_tab_value = Module?.tab_list[0]?.add_tab ? Module?.tab_list[0]?.add_tab?.split("_") : []
            setDepartment(split_tab_value[0])
            setSearchType(split_tab_value[1])
        }
    },[Module])

    const handleChange = (event) =>{
        var get_tab_key = event.target.attributes.getNamedItem("data-rr-ui-event-key").value;
        var split_tab_value = get_tab_key.split("_")
        setDepartment(split_tab_value[0])
        setSearchType(split_tab_value[1])
        //console.log("get_tab_key", get_tab_key);
        //console.log("tab_key", event.target.attributes.getNamedItem("data-rr-ui-event-key").value)
    }

    useEffect(() =>{
        if(department === "residential" && search_type === "lettings"){
            setPropertiesList(let_properties)
        } else{
            setPropertiesList(sales_properties)
        }
        
    },[department, search_type])
    
    var settings = {
        dots: false,
        arrows: true,
        infinite: properties_list?.length > 4 ? true : false,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 4,
        mobileFirst: true,
        autoplay:true,
        autoplaySpeed:5000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: properties_list?.length > 3 ? true : false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: properties_list?.length > 2 ? true : false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 1 ? true : false,
                },
            },

        ],
    };


    //console.log("tab_list", Module?.tab_list?.length);

    return (
        <React.Fragment>
            {Module?.tab_list?.length > 0 && 
            <section className={`features ${Module.background_clr} ${Module?.tab_list?.length > 1 ? "" : "hide_tab"}`}>
                
                <Container>
                    <Row>
                        <Col>
                            <h2>{Module?.title ? Module?.title : "Featured Properties"}</h2>
                            <div className="tab-wrapper">
                                {Module?.tab_list?.length > 0 && (
                                    <Tabs
                                        defaultActiveKey={Module?.tab_list[0]?.add_tab}
                                        id="uncontrolled-tab-example"
                                        onClick={handleChange}
                                        >
                                        {Module?.tab_list.map((item, index) => {
                                            return(
                                                <Tab eventKey={item.add_tab} title={item.tab_name} >
                                                    {properties_list?.length > 0 && 
                                                    <Slider {...settings}>
                                                        {properties_list?.map((item, index) => (
                                                            <PropertyCard key={index} FeaturesList={item} />
                                                        ))}
                                                    </Slider>
                                                    }
                                                </Tab>
                                            )
                                        })}                                        
                                    </Tabs>
                                )
                                }                                
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            }
           
        </React.Fragment>
    );
};
export default Features;
